import React from 'react';
import  "./Skills.css"
const Skills = ({className}) => (
    <section  className="skills-section">
        <h2>Skills</h2>
        <ul>
            <li>Programming Languages:<span className="program"> Java,Python, Go, Dart, JavaScript</span></li>
            <li>Web technologies:<span className="technologie"> Angular, ReactJS, NodeJS, Spring Boot</span></li>
            <li>Mobile Development:<span className="mobile">React Native, Flutter</span></li>
            <li>Testing:<span className="test">Unit tests (Junit), e2e tests (Selenium)</span></li>
            <li>Languages:<span className="language">Arabic (Native), English (Professional B2), French (Professional C1)</span></li>
        </ul>
    </section>
);

export default Skills;
