import React from 'react';
import "./Header.css"
import Profile from "./Profile";

const Header = () => (
    <header  className="animated fadeIn">
        <Profile />
        <h1>Bechir Chedly</h1>
        <nav>
            <a href="https://www.linkedin.com/in/bechirchedly/">LinkedIn</a>
            <a href="https://www.tecost.ch/">TecostSA</a>
            <a href="https://www.aderivatives.com/">ADS</a>
            <a href="https://hey-mate-admin.vercel.app/">Heymate</a>
        </nav>
    </header>
);

export default Header;
