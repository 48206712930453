import React, {useEffect} from 'react';
import './App.css';
import Header from './components/Header';
import Education from './components/Education';
import Skills from './components/Skills';
import Experience from './components/Experience';
import Interests from './components/Interests';
import Footer from './components/Footer';

function App() {
    const sectionContainer = 'section-container ';
    const educationClassName ='education-container';
    const skillClassName ='skill-container';
    const experienceClassName ='experience-container';
    const interestClassName ='interest-container';

    const updateCssVariable = () =>{
        const wv = window.innerWidth;
        const calculatedValue = wv * 0.5;
        document.documentElement.style.setProperty("--dynamic-width", `${calculatedValue}px`);
    }

    useEffect(() => {
        updateCssVariable();
        window.addEventListener('resize', updateCssVariable);
        return () => {
             window.removeEventListener('resize', updateCssVariable);
        }
    }, []);
  return (
      <div className="App">
          {/*<Profile />*/}
        <Header />
        <main className="main-section">
          <Education className={sectionContainer + educationClassName} />
          <Skills className={sectionContainer + skillClassName} />
          <Experience className={sectionContainer + experienceClassName} />
          <Interests className={interestClassName} />
        </main>
        <Footer />
      </div>
  );
}

export default App;
