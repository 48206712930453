import React, {useState} from 'react';
import "./Profile.css";
import ProfileImage from "../assets/images/bechirAnim.png"

const Profile = () => {

    const [tooltip, setTooltip] = useState({ visible: false, text: '', x: 0, y: 0 });

    const handleMouseEnter = (event, text) => {
        const x = event.pageX;
        const y = event.pageY + 10;
        setTooltip({ visible: true, text, x, y });
    };

    const handleMouseLeave = () => {
        setTooltip({ visible: false, text: '', x: 0, y: 0 });
    };

    return (


        <article className="orbit-container">
            <figure>
                <img src={ProfileImage} alt="profile" className="orbiting-image" width="300" height="300" useMap="#image-map"/>
                {tooltip.visible && (
                    <div className="tooltip" style={{ left: tooltip.x, top: tooltip.y }}>
                        {tooltip.text}
                    </div>
                )}
                <map name="image-map">
                    <area
                        shape="rect"
                        coords="0,0,260,129"
                        alt="Area 1"
                        href="#"
                        onMouseEnter={(e) => handleMouseEnter(e, 'Be more creative')}
                        onMouseLeave={handleMouseLeave}
                    />
                    <area
                        shape="rect"
                            coords="0,129,260,386"
                        alt="Area 2"
                        href="#"
                        onMouseEnter={(e) => handleMouseEnter(e, 'Never give up to get this body ')}
                        onMouseLeave={handleMouseLeave}
                    />
                </map>
            </figure>
        </article>
    );
};

export default Profile;