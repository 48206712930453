import React from 'react';
import "./Experience.css"

const Experience = ({className}) => (
    <section className={className}>
        <h2>Experience & Projects</h2>
        <div>
            <h3>TecostSA</h3>
            <p>Software Engineer</p>
            <p>Aug/2023 – Now</p>
            <p>
                <ul>
                    <li>Fix bugs and optimize performance</li>
                    <li>Write Unit tests and participate in code reviews</li>
                    <li>Build new components in Java for a better UI</li>
                </ul>
            </p>
        </div>
        <div>
            <h3>ADS</h3>
            <p>Software Engineer</p>
            <p>Feb/2021 – Jun/2023</p>
            <p>
                <ul>
                    <li>Design and develop Matchini, a Web application for invoice management</li>
                    <li>Design new functionalities, develop, and maintain the Innogec platform</li>
                    <li>Develop microservices architecture: Rest API, Spring Boot, MySQL</li>
                </ul>
            </p>
        </div>
        <div>
            <h3>Heymate</h3>
            <p>Freelance Software Engineer</p>
            <p>Aug/2023 – Mar/2024</p>
            <p>
                <ul>
                    <li>Design and develop a mobile application for car sharing services in Australia</li>
                    <li>Provide users with a platform to search for available trips or suggest their own</li>
                    <li>Provide a user-friendly interface using Flutter, NodeJS, and Express</li>
                </ul>
            </p>
        </div>
    </section>
);

export default Experience;
