import React from 'react';
import "./Interests.css"

const Interests = ({className}) => (
    <section  className={className}>
        <h2>Interests</h2>
        <ul>
            <li>Astronomy</li>
            <li>Magic Tricks</li>
            <li>Sports: billiard, snooker, football, calisthenics</li>
        </ul>
    </section>
);

export default Interests;
