import React from 'react';
import './Education.css';

const Education = ({className}) => (
    <section className={className}>
        <h2>Education</h2>
        <div>
            <h3>National Engineering School Of Sousse (ENISO)</h3>
            <p>Engineering Degree in Applied Computer Science (Master’s degree M2)</p>
            <p>Sep/2018 – Sep/2021</p>
        </div>
        <div>
            <h3>Preparatory Institute for Engineering Studies of El Manar</h3>
            <p>Technology-Physics Diploma</p>
            <p>Sep/2016 - Jun/2018</p>
        </div>
    </section>
);

export default Education;
